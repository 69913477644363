// Make sure the return type matches the one in .app/composables/useTheme.ts
// We need to figure out a better solution for this.
export default function useColorConfig() {
  const { tenant } = useTenant()

  const isMas4you = computed(() => tenant.value == 'mas4you')

  // Mas4you orange
  // Created through https://www.tints.dev/brand/FF00B4
  const mas4youPrimaryColorShades = `{
    "50": "#FFF0E6",
    "100": "#FFE1CC",
    "200": "#FFC499",
    "300": "#FEA667",
    "400": "#FE8834",
    "500": "#FE6D02",
    "600": "#CB5501",
    "700": "#984001",
    "800": "#662B00",
    "900": "#331500",
    "950": "#190B00"
  }`

  // Mas4you secondary
  // Created through https://www.tints.dev/brand/FF00B4
  const mas4youSecondaryColorShades = `{
    "50": "#FCF2FD",
    "100": "#F9E5FA", 
    "200": "#F3C7F5",
    "300": "#EDADF0",
    "400": "#E68FEB",
    "500": "#E175E6",
    "600": "#D338DB",
    "700": "#AA20B1",
    "800": "#701575",
    "900": "#3A0B3C",
    "950": "#1D051E"
  }`

  // Mas4you pink background color
  const mas4youBackgroundColor = '#fdf2fd'

  const primaryColorPreset = ref(isMas4you.value ? undefined : 'indigo')
  const primaryColorShades = ref<string | undefined>(isMas4you.value ? mas4youPrimaryColorShades : undefined)
  const secondaryColorPreset = ref(isMas4you.value ? undefined : 'slate')
  const secondaryColorShades = ref<string | undefined>(isMas4you.value ? mas4youSecondaryColorShades : undefined)
  const mutedColorPreset = ref<string | undefined>(undefined)
  const backgroundColor = ref<string | undefined>(isMas4you.value ? mas4youBackgroundColor : '#f9fafb')
  const textColor = ref<string | undefined>(undefined)

  return {
    primaryColorShades,
    primaryColorPreset,
    secondaryColorPreset,
    secondaryColorShades,
    mutedColorPreset,
    backgroundColor,
    textColor,
  }
}
