export default defineAppConfig({
  app: {
    allowInactiveUsers: true,
    useI18n: false,
    isStagedoos: true,
  },
  nui: {
    // Note: changed in latest tairo version.
    defaultShapes: {
      button: 'full',
    },
  },
})
