import { default as __indexIUfKi05x4WMeta } from "/vercel/path0/layers/tairo/pages/__index.vue?macro=true";
import { default as _91_91slug_93_934wkjTaWaIPMeta } from "/vercel/path0/stagedoos/pages/[[slug]].vue?macro=true";
import { default as index_4528SAiEe01y8Meta } from "/vercel/path0/.app/pages/analytics/index-2.vue?macro=true";
import { default as indexTS7YDSsDxsMeta } from "/vercel/path0/.app/pages/analytics/index.vue?macro=true";
import { default as login_451PzktOBWuMrMeta } from "/vercel/path0/.app/pages/auth/login-1.vue?macro=true";
import { default as recoverDHSNCySTpTMeta } from "/vercel/path0/.app/pages/auth/recover.vue?macro=true";
import { default as reset389BRdnuMwMeta } from "/vercel/path0/.app/pages/auth/reset.vue?macro=true";
import { default as token5YDpd8Z7VVMeta } from "/vercel/path0/.app/pages/auth/token.vue?macro=true";
import { default as indexDOGt0SodpyMeta } from "/vercel/path0/.app/pages/confirm/index.vue?macro=true";
import { default as indexixbdmcTsmCMeta } from "/vercel/path0/.app/pages/content/index.vue?macro=true";
import { default as menu_45itemssqOm1XOhXAMeta } from "/vercel/path0/.app/pages/content/menu-items.vue?macro=true";
import { default as stock_45imagesTXmEgzNzGHMeta } from "/vercel/path0/.app/pages/content/stock-images.vue?macro=true";
import { default as contentTavsgksC19Meta } from "/vercel/path0/.app/pages/content.vue?macro=true";
import { default as editBOMAhLSPhYMeta } from "/vercel/path0/stagedoos/pages/contract_templates/[id]/edit.vue?macro=true";
import { default as indexO6jJumkuhIMeta } from "/vercel/path0/stagedoos/pages/contract_templates/index.vue?macro=true";
import { default as indexbPRpJanaU4Meta } from "/vercel/path0/stagedoos/pages/contracts/[id]/index.vue?macro=true";
import { default as _91signerId_93LLBFcaR690Meta } from "/vercel/path0/stagedoos/pages/contracts/[id]/qr-sign/[signerId].vue?macro=true";
import { default as _91signer_id_93drMzfOSK0dMeta } from "/vercel/path0/stagedoos/pages/contracts/[id]/sign/[signer_id].vue?macro=true";
import { default as conversationsPeTz0Z7yCtMeta } from "/vercel/path0/stagedoos/pages/conversations.vue?macro=true";
import { default as dashboardBOcXGg8wRkMeta } from "/vercel/path0/stagedoos/pages/dashboard.vue?macro=true";
import { default as _91_91token_93_93u7VSBYrSGIMeta } from "/vercel/path0/.app/pages/forms/[[token]].vue?macro=true";
import { default as editgnJzgPpaRVMeta } from "/vercel/path0/stagedoos/pages/groups/[id]/edit.vue?macro=true";
import { default as index9S2ktcAHaBMeta } from "/vercel/path0/stagedoos/pages/groups/[id]/index.vue?macro=true";
import { default as addfsIi597JBGMeta } from "/vercel/path0/stagedoos/pages/groups/add.vue?macro=true";
import { default as indexJolRkOXECQMeta } from "/vercel/path0/stagedoos/pages/groups/index.vue?macro=true";
import { default as indexFu4bNWwbElMeta } from "/vercel/path0/stagedoos/pages/import_groups/index.vue?macro=true";
import { default as indexiGdjxCtcJKMeta } from "/vercel/path0/stagedoos/pages/index.vue?macro=true";
import { default as inloggen33i0JW8OPjMeta } from "/vercel/path0/stagedoos/pages/inloggen.vue?macro=true";
import { default as indexSoCpeoUEVMMeta } from "/vercel/path0/stagedoos/pages/instellingen/index.vue?macro=true";
import { default as editRtxKbV3vADMeta } from "/vercel/path0/stagedoos/pages/internship_periods/[id]/edit.vue?macro=true";
import { default as add03Bonw2dwMMeta } from "/vercel/path0/stagedoos/pages/internship_periods/add.vue?macro=true";
import { default as index4oyC9aVbVSMeta } from "/vercel/path0/stagedoos/pages/internship_periods/index.vue?macro=true";
import { default as editY20Sw3nn1kMeta } from "/vercel/path0/stagedoos/pages/internships/[id]/edit.vue?macro=true";
import { default as indexmrRsfE0YInMeta } from "/vercel/path0/stagedoos/pages/internships/[id]/index.vue?macro=true";
import { default as assignAtl0GT49DfMeta } from "/vercel/path0/stagedoos/pages/internships/assign.vue?macro=true";
import { default as indexhUtC7smSrFMeta } from "/vercel/path0/stagedoos/pages/internships/index.vue?macro=true";
import { default as adda6awO3cvmzMeta } from "/vercel/path0/stagedoos/pages/mentorships/add.vue?macro=true";
import { default as indexxywXcPxppNMeta } from "/vercel/path0/stagedoos/pages/mentorships/index.vue?macro=true";
import { default as _91_91slug_93_93bKqByCqtIUMeta } from "/vercel/path0/.app/pages/nieuws/[[slug]].vue?macro=true";
import { default as indexwk9YNLKAguMeta } from "/vercel/path0/.app/pages/notificaties/index.vue?macro=true";
import { default as editIAlTlZPyAVMeta } from "/vercel/path0/.app/pages/notification_templates/[id]/edit.vue?macro=true";
import { default as indexSyvqXTaJnyMeta } from "/vercel/path0/.app/pages/notification_templates/index.vue?macro=true";
import { default as editfvbLSXHjQLMeta } from "/vercel/path0/stagedoos/pages/organizations/[id]/edit.vue?macro=true";
import { default as indexcMpEBUKd7KMeta } from "/vercel/path0/stagedoos/pages/organizations/[id]/index.vue?macro=true";
import { default as addycMCuVYsuvMeta } from "/vercel/path0/stagedoos/pages/organizations/add.vue?macro=true";
import { default as approvehEM7f8ipaoMeta } from "/vercel/path0/stagedoos/pages/organizations/approve.vue?macro=true";
import { default as indexRekqGZqDU5Meta } from "/vercel/path0/stagedoos/pages/organizations/index.vue?macro=true";
import { default as indexupW52PgBHoMeta } from "/vercel/path0/stagedoos/pages/organizations/signup/index.vue?macro=true";
import { default as successPZNER546NdMeta } from "/vercel/path0/stagedoos/pages/organizations/signup/success.vue?macro=true";
import { default as privacyverklaringdnL095B7kkMeta } from "/vercel/path0/stagedoos/pages/privacyverklaring.vue?macro=true";
import { default as emaildsk2IOg4EfMeta } from "/vercel/path0/stagedoos/pages/profile-edit/email.vue?macro=true";
import { default as indexrO34vTUF6EMeta } from "/vercel/path0/.app/pages/profile-edit/index.vue?macro=true";
import { default as settingsbDmHjK84MMMeta } from "/vercel/path0/.app/pages/profile-edit/settings.vue?macro=true";
import { default as profile_45edit0oMFymvf4iMeta } from "/vercel/path0/.app/pages/profile-edit.vue?macro=true";
import { default as recente_acties4JTw8OyHl6Meta } from "/vercel/path0/stagedoos/pages/recente_acties.vue?macro=true";
import { default as indexTu2yZLPwe0Meta } from "/vercel/path0/stagedoos/pages/stagebank/index.vue?macro=true";
import { default as index9f7GSYX76uMeta } from "/vercel/path0/stagedoos/pages/stagevoortgang/index.vue?macro=true";
import { default as uitlegguLVBPC5C3Meta } from "/vercel/path0/stagedoos/pages/stagevoortgang/uitleg.vue?macro=true";
import { default as assign_groupjBEpRtVxOFMeta } from "/vercel/path0/stagedoos/pages/students/[id]/assign_group.vue?macro=true";
import { default as authkaSMnQT4WcMeta } from "/vercel/path0/.app/pages/test/auth.vue?macro=true";
import { default as datepicker2DaAkr5UulMeta } from "/vercel/path0/.app/pages/test/datepicker.vue?macro=true";
import { default as dollar_45api52kSPhxjwRMeta } from "/vercel/path0/.app/pages/test/dollar-api.vue?macro=true";
import { default as dupeI47ZtOXqSqMeta } from "/vercel/path0/.app/pages/test/dupe.vue?macro=true";
import { default as formkm7kcPhdf0Meta } from "/vercel/path0/.app/pages/test/form.vue?macro=true";
import { default as formbuildero2fxBjU3qNMeta } from "/vercel/path0/.app/pages/test/formbuilder.vue?macro=true";
import { default as sentryVCPZPlthOwMeta } from "/vercel/path0/.app/pages/test/sentry.vue?macro=true";
import { default as server_45errorzkZTzpIMvfMeta } from "/vercel/path0/.app/pages/test/server-error.vue?macro=true";
import { default as stagedoos_fieldspb2rEOny0PMeta } from "/vercel/path0/stagedoos/pages/test/stagedoos_fields.vue?macro=true";
import { default as step_45form_452zOUvlFOLgwMeta } from "/vercel/path0/.app/pages/test/step-form-2.vue?macro=true";
import { default as step_45formbVuEsOyEdOMeta } from "/vercel/path0/.app/pages/test/step-form.vue?macro=true";
import { default as toasterdGo68mWjbTMeta } from "/vercel/path0/.app/pages/test/toaster.vue?macro=true";
import { default as user_45storeNZaKEdPvEJMeta } from "/vercel/path0/.app/pages/test/user-store.vue?macro=true";
import { default as usermbs7HBNeeAMeta } from "/vercel/path0/.app/pages/test/user.vue?macro=true";
import { default as uitloggenJpGYx4npziMeta } from "/vercel/path0/.app/pages/uitloggen.vue?macro=true";
import { default as _91id_93ZbnqYzJMoNMeta } from "/vercel/path0/stagedoos/pages/urenbriefje/[id].vue?macro=true";
import { default as add_role1rGOgsOd7sMeta } from "/vercel/path0/.app/pages/users/[id]/add_role.vue?macro=true";
import { default as edit2D4CWW5dzWMeta } from "/vercel/path0/.app/pages/users/[id]/edit.vue?macro=true";
import { default as indexfosjwbxWtaMeta } from "/vercel/path0/.app/pages/users/[id]/index.vue?macro=true";
import { default as adds9wtR2FJuZMeta } from "/vercel/path0/.app/pages/users/add.vue?macro=true";
import { default as indexdlFaUjo5BDMeta } from "/vercel/path0/.app/pages/users/index.vue?macro=true";
import { default as edito5crPmYslWMeta } from "/vercel/path0/stagedoos/pages/vacancies/[id]/edit.vue?macro=true";
import { default as indexQASgYGPynxMeta } from "/vercel/path0/stagedoos/pages/vacancies/[id]/index.vue?macro=true";
import { default as addqQCfItJQmNMeta } from "/vercel/path0/stagedoos/pages/vacancies/add.vue?macro=true";
import { default as indexWZnTPYQ2NQMeta } from "/vercel/path0/stagedoos/pages/vacancies/index.vue?macro=true";
import { default as indexHq2dcm1jMAMeta } from "/vercel/path0/stagedoos/pages/workflows/[id]/[phase_id]/index.vue?macro=true";
import { default as indexNXogjRzmdTMeta } from "/vercel/path0/stagedoos/pages/workflows/[id]/index.vue?macro=true";
import { default as indexAr3FAPgfCeMeta } from "/vercel/path0/stagedoos/pages/workflows/index.vue?macro=true";
import { default as zelfgevondenVLq5PsPnEwMeta } from "/vercel/path0/stagedoos/pages/zelfgevonden.vue?macro=true";
export default [
  {
    name: "__index",
    path: "/__index",
    component: () => import("/vercel/path0/layers/tairo/pages/__index.vue")
  },
  {
    name: "slug",
    path: "/:slug?",
    meta: _91_91slug_93_934wkjTaWaIPMeta || {},
    component: () => import("/vercel/path0/stagedoos/pages/[[slug]].vue")
  },
  {
    name: "analytics-index-2",
    path: "/analytics/index-2",
    component: () => import("/vercel/path0/.app/pages/analytics/index-2.vue")
  },
  {
    name: "analytics",
    path: "/analytics",
    meta: indexTS7YDSsDxsMeta || {},
    component: () => import("/vercel/path0/.app/pages/analytics/index.vue")
  },
  {
    name: "auth-login-1",
    path: "/auth/login-1",
    meta: login_451PzktOBWuMrMeta || {},
    component: () => import("/vercel/path0/.app/pages/auth/login-1.vue")
  },
  {
    name: "auth-recover",
    path: "/auth/recover",
    meta: recoverDHSNCySTpTMeta || {},
    component: () => import("/vercel/path0/.app/pages/auth/recover.vue")
  },
  {
    name: "auth-reset",
    path: "/auth/reset",
    meta: reset389BRdnuMwMeta || {},
    component: () => import("/vercel/path0/.app/pages/auth/reset.vue")
  },
  {
    name: "auth-token",
    path: "/auth/token",
    component: () => import("/vercel/path0/.app/pages/auth/token.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: indexDOGt0SodpyMeta || {},
    component: () => import("/vercel/path0/.app/pages/confirm/index.vue")
  },
  {
    name: contentTavsgksC19Meta?.name,
    path: "/content",
    component: () => import("/vercel/path0/.app/pages/content.vue"),
    children: [
  {
    name: "content",
    path: "",
    component: () => import("/vercel/path0/.app/pages/content/index.vue")
  },
  {
    name: "content-menu-items",
    path: "menu-items",
    component: () => import("/vercel/path0/.app/pages/content/menu-items.vue")
  },
  {
    name: "content-stock-images",
    path: "stock-images",
    component: () => import("/vercel/path0/.app/pages/content/stock-images.vue")
  }
]
  },
  {
    name: "contract_templates-id-edit",
    path: "/contract_templates/:id()/edit",
    meta: editBOMAhLSPhYMeta || {},
    component: () => import("/vercel/path0/stagedoos/pages/contract_templates/[id]/edit.vue")
  },
  {
    name: "contract_templates",
    path: "/contract_templates",
    meta: indexO6jJumkuhIMeta || {},
    component: () => import("/vercel/path0/stagedoos/pages/contract_templates/index.vue")
  },
  {
    name: "contracts-id",
    path: "/contracts/:id()",
    meta: indexbPRpJanaU4Meta || {},
    component: () => import("/vercel/path0/stagedoos/pages/contracts/[id]/index.vue")
  },
  {
    name: "contracts-id-qr-sign-signerId",
    path: "/contracts/:id()/qr-sign/:signerId()",
    meta: _91signerId_93LLBFcaR690Meta || {},
    component: () => import("/vercel/path0/stagedoos/pages/contracts/[id]/qr-sign/[signerId].vue")
  },
  {
    name: "contracts-id-sign-signer_id",
    path: "/contracts/:id()/sign/:signer_id()",
    meta: _91signer_id_93drMzfOSK0dMeta || {},
    component: () => import("/vercel/path0/stagedoos/pages/contracts/[id]/sign/[signer_id].vue")
  },
  {
    name: "conversations",
    path: "/conversations",
    component: () => import("/vercel/path0/stagedoos/pages/conversations.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/vercel/path0/stagedoos/pages/dashboard.vue")
  },
  {
    name: "forms-token",
    path: "/forms/:token?",
    meta: _91_91token_93_93u7VSBYrSGIMeta || {},
    component: () => import("/vercel/path0/.app/pages/forms/[[token]].vue")
  },
  {
    name: "groups-id-edit",
    path: "/groups/:id()/edit",
    component: () => import("/vercel/path0/stagedoos/pages/groups/[id]/edit.vue")
  },
  {
    name: "groups-id",
    path: "/groups/:id()",
    component: () => import("/vercel/path0/stagedoos/pages/groups/[id]/index.vue")
  },
  {
    name: "groups-add",
    path: "/groups/add",
    component: () => import("/vercel/path0/stagedoos/pages/groups/add.vue")
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/vercel/path0/stagedoos/pages/groups/index.vue")
  },
  {
    name: "import_groups",
    path: "/import_groups",
    component: () => import("/vercel/path0/stagedoos/pages/import_groups/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexiGdjxCtcJKMeta || {},
    component: () => import("/vercel/path0/stagedoos/pages/index.vue")
  },
  {
    name: "inloggen",
    path: "/inloggen",
    meta: inloggen33i0JW8OPjMeta || {},
    component: () => import("/vercel/path0/stagedoos/pages/inloggen.vue")
  },
  {
    name: "instellingen",
    path: "/instellingen",
    component: () => import("/vercel/path0/stagedoos/pages/instellingen/index.vue")
  },
  {
    name: "internship_periods-id-edit",
    path: "/internship_periods/:id()/edit",
    component: () => import("/vercel/path0/stagedoos/pages/internship_periods/[id]/edit.vue")
  },
  {
    name: "internship_periods-add",
    path: "/internship_periods/add",
    component: () => import("/vercel/path0/stagedoos/pages/internship_periods/add.vue")
  },
  {
    name: "internship_periods",
    path: "/internship_periods",
    component: () => import("/vercel/path0/stagedoos/pages/internship_periods/index.vue")
  },
  {
    name: "internships-id-edit",
    path: "/internships/:id()/edit",
    component: () => import("/vercel/path0/stagedoos/pages/internships/[id]/edit.vue")
  },
  {
    name: "internships-id",
    path: "/internships/:id()",
    component: () => import("/vercel/path0/stagedoos/pages/internships/[id]/index.vue")
  },
  {
    name: "internships-assign",
    path: "/internships/assign",
    component: () => import("/vercel/path0/stagedoos/pages/internships/assign.vue")
  },
  {
    name: "internships",
    path: "/internships",
    component: () => import("/vercel/path0/stagedoos/pages/internships/index.vue")
  },
  {
    name: "mentorships-add",
    path: "/mentorships/add",
    component: () => import("/vercel/path0/stagedoos/pages/mentorships/add.vue")
  },
  {
    name: "mentorships",
    path: "/mentorships",
    component: () => import("/vercel/path0/stagedoos/pages/mentorships/index.vue")
  },
  {
    name: "nieuws-slug",
    path: "/nieuws/:slug?",
    meta: _91_91slug_93_93bKqByCqtIUMeta || {},
    component: () => import("/vercel/path0/.app/pages/nieuws/[[slug]].vue")
  },
  {
    name: "notificaties",
    path: "/notificaties",
    component: () => import("/vercel/path0/.app/pages/notificaties/index.vue")
  },
  {
    name: "notification_templates-id-edit",
    path: "/notification_templates/:id()/edit",
    component: () => import("/vercel/path0/.app/pages/notification_templates/[id]/edit.vue")
  },
  {
    name: "notification_templates",
    path: "/notification_templates",
    component: () => import("/vercel/path0/.app/pages/notification_templates/index.vue")
  },
  {
    name: "organizations-id-edit",
    path: "/organizations/:id()/edit",
    component: () => import("/vercel/path0/stagedoos/pages/organizations/[id]/edit.vue")
  },
  {
    name: "organizations-id",
    path: "/organizations/:id()",
    component: () => import("/vercel/path0/stagedoos/pages/organizations/[id]/index.vue")
  },
  {
    name: "organizations-add",
    path: "/organizations/add",
    component: () => import("/vercel/path0/stagedoos/pages/organizations/add.vue")
  },
  {
    name: "organizations-approve",
    path: "/organizations/approve",
    component: () => import("/vercel/path0/stagedoos/pages/organizations/approve.vue")
  },
  {
    name: "organizations",
    path: "/organizations",
    component: () => import("/vercel/path0/stagedoos/pages/organizations/index.vue")
  },
  {
    name: "organizations-signup",
    path: "/organizations/signup",
    meta: indexupW52PgBHoMeta || {},
    component: () => import("/vercel/path0/stagedoos/pages/organizations/signup/index.vue")
  },
  {
    name: "organizations-signup-success",
    path: "/organizations/signup/success",
    meta: successPZNER546NdMeta || {},
    component: () => import("/vercel/path0/stagedoos/pages/organizations/signup/success.vue")
  },
  {
    name: "privacyverklaring",
    path: "/privacyverklaring",
    component: () => import("/vercel/path0/stagedoos/pages/privacyverklaring.vue")
  },
  {
    name: profile_45edit0oMFymvf4iMeta?.name,
    path: "/profile-edit",
    component: () => import("/vercel/path0/.app/pages/profile-edit.vue"),
    children: [
  {
    name: "profile-edit-email",
    path: "email",
    component: () => import("/vercel/path0/stagedoos/pages/profile-edit/email.vue")
  },
  {
    name: "profile-edit",
    path: "",
    meta: indexrO34vTUF6EMeta || {},
    component: () => import("/vercel/path0/.app/pages/profile-edit/index.vue")
  },
  {
    name: "profile-edit-settings",
    path: "settings",
    meta: settingsbDmHjK84MMMeta || {},
    component: () => import("/vercel/path0/.app/pages/profile-edit/settings.vue")
  }
]
  },
  {
    name: "recente_acties",
    path: "/recente_acties",
    component: () => import("/vercel/path0/stagedoos/pages/recente_acties.vue")
  },
  {
    name: "stagebank",
    path: "/stagebank",
    component: () => import("/vercel/path0/stagedoos/pages/stagebank/index.vue")
  },
  {
    name: "stagevoortgang",
    path: "/stagevoortgang",
    component: () => import("/vercel/path0/stagedoos/pages/stagevoortgang/index.vue")
  },
  {
    name: "stagevoortgang-uitleg",
    path: "/stagevoortgang/uitleg",
    component: () => import("/vercel/path0/stagedoos/pages/stagevoortgang/uitleg.vue")
  },
  {
    name: "students-id-assign_group",
    path: "/students/:id()/assign_group",
    component: () => import("/vercel/path0/stagedoos/pages/students/[id]/assign_group.vue")
  },
  {
    name: "test-auth",
    path: "/test/auth",
    meta: authkaSMnQT4WcMeta || {},
    component: () => import("/vercel/path0/.app/pages/test/auth.vue")
  },
  {
    name: "test-datepicker",
    path: "/test/datepicker",
    meta: datepicker2DaAkr5UulMeta || {},
    component: () => import("/vercel/path0/.app/pages/test/datepicker.vue")
  },
  {
    name: "test-dollar-api",
    path: "/test/dollar-api",
    component: () => import("/vercel/path0/.app/pages/test/dollar-api.vue")
  },
  {
    name: "test-dupe",
    path: "/test/dupe",
    meta: dupeI47ZtOXqSqMeta || {},
    component: () => import("/vercel/path0/.app/pages/test/dupe.vue")
  },
  {
    name: "test-form",
    path: "/test/form",
    meta: formkm7kcPhdf0Meta || {},
    component: () => import("/vercel/path0/.app/pages/test/form.vue")
  },
  {
    name: "test-formbuilder",
    path: "/test/formbuilder",
    component: () => import("/vercel/path0/.app/pages/test/formbuilder.vue")
  },
  {
    name: "test-sentry",
    path: "/test/sentry",
    component: () => import("/vercel/path0/.app/pages/test/sentry.vue")
  },
  {
    name: "test-server-error",
    path: "/test/server-error",
    component: () => import("/vercel/path0/.app/pages/test/server-error.vue")
  },
  {
    name: "test-stagedoos_fields",
    path: "/test/stagedoos_fields",
    component: () => import("/vercel/path0/stagedoos/pages/test/stagedoos_fields.vue")
  },
  {
    name: "test-step-form-2",
    path: "/test/step-form-2",
    component: () => import("/vercel/path0/.app/pages/test/step-form-2.vue")
  },
  {
    name: "test-step-form",
    path: "/test/step-form",
    component: () => import("/vercel/path0/.app/pages/test/step-form.vue")
  },
  {
    name: "test-toaster",
    path: "/test/toaster",
    component: () => import("/vercel/path0/.app/pages/test/toaster.vue")
  },
  {
    name: "test-user-store",
    path: "/test/user-store",
    component: () => import("/vercel/path0/.app/pages/test/user-store.vue")
  },
  {
    name: "test-user",
    path: "/test/user",
    meta: usermbs7HBNeeAMeta || {},
    component: () => import("/vercel/path0/.app/pages/test/user.vue")
  },
  {
    name: "uitloggen",
    path: "/uitloggen",
    component: () => import("/vercel/path0/.app/pages/uitloggen.vue")
  },
  {
    name: "urenbriefje-id",
    path: "/urenbriefje/:id()",
    meta: _91id_93ZbnqYzJMoNMeta || {},
    component: () => import("/vercel/path0/stagedoos/pages/urenbriefje/[id].vue")
  },
  {
    name: "users-id-add_role",
    path: "/users/:id()/add_role",
    component: () => import("/vercel/path0/.app/pages/users/[id]/add_role.vue")
  },
  {
    name: "users-id-edit",
    path: "/users/:id()/edit",
    component: () => import("/vercel/path0/.app/pages/users/[id]/edit.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/vercel/path0/.app/pages/users/[id]/index.vue")
  },
  {
    name: "users-add",
    path: "/users/add",
    meta: adds9wtR2FJuZMeta || {},
    component: () => import("/vercel/path0/.app/pages/users/add.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/vercel/path0/.app/pages/users/index.vue")
  },
  {
    name: "vacancies-id-edit",
    path: "/vacancies/:id()/edit",
    component: () => import("/vercel/path0/stagedoos/pages/vacancies/[id]/edit.vue")
  },
  {
    name: "vacancies-id",
    path: "/vacancies/:id()",
    component: () => import("/vercel/path0/stagedoos/pages/vacancies/[id]/index.vue")
  },
  {
    name: "vacancies-add",
    path: "/vacancies/add",
    component: () => import("/vercel/path0/stagedoos/pages/vacancies/add.vue")
  },
  {
    name: "vacancies",
    path: "/vacancies",
    component: () => import("/vercel/path0/stagedoos/pages/vacancies/index.vue")
  },
  {
    name: "workflows-id-phase_id",
    path: "/workflows/:id()/:phase_id()",
    component: () => import("/vercel/path0/stagedoos/pages/workflows/[id]/[phase_id]/index.vue")
  },
  {
    name: "workflows-id",
    path: "/workflows/:id()",
    component: () => import("/vercel/path0/stagedoos/pages/workflows/[id]/index.vue")
  },
  {
    name: "workflows",
    path: "/workflows",
    component: () => import("/vercel/path0/stagedoos/pages/workflows/index.vue")
  },
  {
    name: "zelfgevonden",
    path: "/zelfgevonden",
    component: () => import("/vercel/path0/stagedoos/pages/zelfgevonden.vue")
  }
]