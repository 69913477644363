// We're not using Tairo's app config (title) here, because we want to be able to use env vars, at least
// for Stagedoos. For Volunteerly, the title is dependent on the volunteer center.
// export default function useAppName() {
//   const config = useRuntimeConfig()
//   return { appName: ) || '' }
// }

export default function useAppName() {
  const { appName } = useTenantConfig()

  return { appName: computed(() => appName) }
}
