import type { LayoutKey } from '#build/types/layouts'

// Return config for the current tenant, like layout, type of internships, etc.
export default function useTenantConfig() {
  const { tenant } = useTenant()

  const isWhitelabel = ['match2gether', 'mas4you'].includes(tenant.value)
  const traineeshipTenants = ['match2gether', 'axia', 'raayland', 'casteleijn'] // , 'demo']
  const isMas = !traineeshipTenants.includes(tenant.value)
  const isTraineeship = !isMas

  const isDemo = tenant.value == 'demo' // || import.meta.env.DEV
  const isRaayland = tenant.value == 'raayland'

  return {
    // Is this a demo instance?
    isDemo: isDemo,
    // Is this a mas tenant?
    isMas: isMas,
    // Is this tenant using a whitelabel / custom theme?
    isWhitelabel: isWhitelabel,
    // Is this a traineeship tenant?
    isTraineeship: isTraineeship,
    // It this tenant using hours?
    isUsingHours: isMas,
    // It this tenant using evaluation forms?
    isUsingEvaluationForms: (isMas && tenant.value != 'mas4you') || isDemo,
    // It this tenant using printable paper timesheets?
    isUsingPaperTimesheets: isMas && tenant.value == 'mas4you',
    // Is using digital contracts
    isUsingDigitalContracts: isTraineeship,
    // Can students submit self-found internships?
    canStudentsSubmitSelfFoundInternships: isMas || tenant.value != 'match2gether',
    // Push the QR-code as the primary method to sign contracts for students?
    showQrCodeAsPrimaryMethod: isDemo || isRaayland,
    // Should the company sign first before the student can let others sign?
    companyMustSignFirst: isDemo || isRaayland,
    // Theme
    publicLayout: 'stageplein' as LayoutKey,
    // Temp name fix for Corderius - need to discuss when they're ready to go to stageplein.
    appName: isWhitelabel ? useCapitalize(tenant.value) : tenant.value == 'corderius' ? 'Stagedoos Corderius' : `Stageplein ${useCapitalize(tenant.value)}`,
    // todo: add background color present?
    slogan: isMas ? 'De leukste maatschappelijke stages' : 'Het online stageplein voor al je stages.',
  }
}
