
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/vercel/path0/stagedoos/app.config.ts"
import cfg1 from "/vercel/path0/.app/app.config.ts"
import cfg2 from "/vercel/path0/layers/tairo-layout-sidebar/app.config.ts"
import cfg3 from "/vercel/path0/layers/tairo-layout-collapse/app.config.ts"
import cfg4 from "/vercel/path0/layers/tairo-layout-topnav/app.config.ts"
import cfg5 from "/vercel/path0/layers/tairo/app.config.ts"
import cfg6 from "/vercel/path0/node_modules/.pnpm/@shuriken-ui+nuxt@1.8.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.4__azyxx3ljdevo5v2ynqev2kcgyy/node_modules/@shuriken-ui/nuxt/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, cfg4, cfg5, cfg6, inlineConfig)
